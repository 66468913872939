// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-post-js": () => import("./../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-tags-page-js": () => import("./../src/templates/tags-page.js" /* webpackChunkName: "component---src-templates-tags-page-js" */),
  "component---src-templates-tag-posts-js": () => import("./../src/templates/tag-posts.js" /* webpackChunkName: "component---src-templates-tag-posts-js" */),
  "component---src-templates-post-list-cronicas-js": () => import("./../src/templates/post-list-cronicas.js" /* webpackChunkName: "component---src-templates-post-list-cronicas-js" */),
  "component---src-templates-post-list-tecnicos-js": () => import("./../src/templates/post-list-tecnicos.js" /* webpackChunkName: "component---src-templates-post-list-tecnicos-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cursos-js": () => import("./../src/pages/cursos.js" /* webpackChunkName: "component---src-pages-cursos-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vovotoqueiro-js": () => import("./../src/pages/vovotoqueiro.js" /* webpackChunkName: "component---src-pages-vovotoqueiro-js" */)
}

